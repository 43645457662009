<template>
  <div class="main-container">
    <div class="main-heading">
      <h3>{{ "Mini Tournament Config" }}</h3>
      <Button
        :loading="isLoading"
        text="Save"
        type="info"
        :onClick="() => submit()"
      />
    </div>
    <div v-if="configData" class="top-panel">
      <div class="top-panel-item">
        <h4>Event Properties</h4>
        <div class="input-row">
          <p>Event Duration (In Hours)</p>
          <input v-model="configData.duration_in_hours" type="number" />
        </div>
        <div class="input-row">
          <p>Player Count</p>
          <input v-model="configData.player_count" type="number" />
        </div>
        <div class="input-row">
          <p>Chaser Bot Count</p>
          <input v-model="configData.bot_config.chaser_count" type="number" />
        </div>
        <div class="input-row">
          <p>Medium Bot Count</p>
          <input v-model="configData.bot_config.chaser_till_a_point_count" type="number" />
        </div>
        <div class="input-row">
          <p>Easy Bot Count</p>
          <input v-model="configData.bot_config.constant_gain_count" type="number" />
        </div>
        <div class="input-row">
          <div class="tooltip">
            <p>After Win Chaser Win Probability (%)</p>
            <span class="tooltiptext">Probability of chaser bots to win a game immediately after user wins a game.</span>
          </div>
          <input v-model="configData.chaser_win_probability" type="number" />
        </div>
        <div class="input-row">
          <div class="tooltip">
            <p>After Lose Chaser Win Probability (%)</p>
            <span class="tooltiptext">Probability of chaser bots to win a game immediately after user loses a game.</span>
          </div>
          <input v-model="configData.chaser_win_probability_on_lose" type="number" />
        </div>
        <div class="input-row">
          <div class="tooltip">
            <p>Chaser Bot Upper Score Cap</p>
            <span class="tooltiptext">Hard limit for chaser bot scores. After chaser bots exceed this score, they stop playing. (Deviation is randomly applied for each chaser bot)</span>
          </div>
          <input v-model="configData.chaser_bot_gain_cap" type="number" />
        </div>
        <div class="input-row">
          <div class="tooltip">
            <p>Chaser Bot Upper Score Cap Deviation +/-</p>
            <span class="tooltiptext">Hard limit deviation for chaser bot scores. hard limit is computed as following: Random value between UpperScore +- Deviation</span>
          </div>
          <input v-model="configData.chaser_bot_gain_cap_deviation" type="number" />
        </div>
        <div class="input-row">
          <div class="tooltip">
            <p>Chaser Let User Win After Hours</p>
            <span class="tooltiptext">After that hour chaser bots wont play. To disable this feature set it to tournament duration</span>
          </div>
          <p></p>
          <input v-model="configData.chaser_let_user_go_after" type="number" />
        </div>
        <div class="input-row">
          <p>Easy Bot Minimum Score</p>
          <input v-model="configData.easy_bot_min_gain" type="number" />
        </div>
        <div class="input-row">
          <p>Easy Bot Maximum Score</p>
          <input v-model="configData.easy_bot_max_gain" type="number" />
        </div>
        <div class="input-row">
          <p>Medium Bot Minimum Score</p>
          <input v-model="configData.medium_bot_min_gain" type="number" />
        </div>
        <div class="input-row">
          <p>Medium Bot Maximum Score</p>
          <input v-model="configData.medium_bot_max_gain" type="number" />
        </div>
        <div class="input-row">
          <p>Normal Bot Update Probability (%)</p>
          <input v-model="configData.normal_bot_update_probability" type="number" />
        </div>
        <div class="input-row">
          <p>Chaser Update Frequency (Minutes)</p>
          <input v-model="configData.chaser_update_threshold_minutes" type="number" />
        </div>
        <div class="input-row">
          <div class="tooltip">
            <p>Chaser Max Exceeds User</p>
            <span class="tooltiptext">How much can chaser bot exceed user on score</span>
          </div>
          <input v-model="configData.chaser_exceed_user_limit" type="number" />
        </div>
      </div>
      <div class="top-panel-item">
        <div class="main-heading">
          <h4>Rewards</h4>
          <div style="display: flex; flex-direction: row;">
            <Button size="sm" text="+" :disabled="Object.keys(configData.rewards).length >= configData.player_count" type="success" :onClick="() => updateRewards(true)" />
            <Button size="sm" text="-" :disabled="Object.keys(configData.rewards).length === 1" type="error" :onClick="() => updateRewards(false)" />
          </div>
        </div>
        <div class="reward-row bold">
          <p>Rank</p>
          <p>Chest</p>
          <p>Amount</p>
        </div>
        <div
          class="reward-row"
          v-for="(item, index) in Object.keys(configData?.rewards ?? {})"
          v-bind:key="index"
        >
          <p>{{ index + 1 + ". Rank" }}</p>
          <select v-model="configData.rewards[item][0].chest_id">
            <option
              v-for="option in chests"
              :value="option.id"
              v-bind:key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <input v-model="configData.rewards[item][0].amount" type="number" />
        </div>
      </div>
    </div>
    <div v-if="configData" class="arena_container">
      <div class="arena-item">
        <h4>Arena Based Data</h4>
      </div>
      <div v-for="(item, index) in Object.keys(configData.arena_data)" v-bind:key="index" :class="'arena-item ' + (item == selectedArena ? '' : 'interactable')" @click="() => selectArena(item)">
        <h4>{{ "Arena " + item }}</h4>
        <div v-if="item == selectedArena">
          <div class="input-row">
            <p>Bot Min Win Rate</p>
            <input v-model="configData.arena_data[item].min_winrate" type="number" />
          </div>
          <div class="input-row">
            <p>Bot Max Win Rate</p>
            <input v-model="configData.arena_data[item].max_winrate" type="number" />
          </div>
          <div class="main-heading">
            <h5>Chaser Decks</h5>
            <Button size="sm" text="+" type="success" :onClick="() => addChaserDeck(item)" />
          </div>
          <div v-for="(deck, index) in configData.arena_data[item].chaser_decks" v-bind:key="index" class="deck">
            <select v-model="deck.hero">
              <option v-for="hero in heroes" v-bind:key="hero.id" :value="hero.id">{{ hero.name }}</option>
            </select>
            <select v-model="deck.units[0]">
              <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
            </select>
            <select v-model="deck.units[1]">
              <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
            </select>
            <select v-model="deck.units[2]">
              <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
            </select>
            <select v-model="deck.units[3]">
              <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
            </select>
            <select v-model="deck.units[4]">
              <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
            </select>
            <p class="clickable-text right-align" @click="() => removeChaserDeck(item, index)">Remove</p>
          </div>
          <div class="main-heading">
            <h5>Decks</h5>
            <Button size="sm" text="+" type="success" :onClick="() => addDeck(item)" />
          </div>
          <div v-for="(deck, index) in configData.arena_data[item].decks" v-bind:key="index" class="deck">
            <select v-model="deck.hero">
              <option v-for="hero in heroes" v-bind:key="hero.id" :value="hero.id">{{ hero.name }}</option>
            </select>
            <select v-model="deck.units[0]">
              <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
            </select>
            <select v-model="deck.units[1]">
              <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
            </select>
            <select v-model="deck.units[2]">
              <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
            </select>
            <select v-model="deck.units[3]">
              <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
            </select>
            <select v-model="deck.units[4]">
              <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
            </select>
            <p class="clickable-text right-align" @click="() => removeDeck(item, index)">Remove</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/common/Button.vue";
import { useStore } from "vuex";
import { onBeforeMount, ref, watch, computed } from "vue";
import dispatchMap from "@/constants/dispatchMap";

export default {
  name: "MiniTournamentConfig",
  components: {
    Button,
  },
  setup() {
    const store = useStore();
    const usedPages = ["events"];
    const isLoading = ref(false);
    const selectedArena = ref("");

    const configData = ref(null);

    onBeforeMount(() => {
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => store.dispatch(dispatchStr));
      });
      store.dispatch("loader/loadingStatus", false);
    });

    watch(
      () => store.getters["serverConfig/getMiniTournamentConfig"],
      (value) => {
        configData.value = JSON.parse(JSON.stringify(value));
      },
      { immediate: true }
    );

    const selectArena = (arenaKey) => {
      selectedArena.value = arenaKey;
    }

    const updateRewards = (isAdd) => {
      const keys = Object.keys(configData.value.rewards).map(x => parseInt(x)).sort((a, b) => b - a)
      if (keys.length === 0 || (!isAdd && keys.length === 1))
        return;
      const biggest = keys[0];

      if (isAdd)
        configData.value.rewards[(biggest+1).toString()] = [{ type: 2, amount: 1 }];
      else 
        delete configData.value.rewards[(biggest).toString()]
    }

    const addChaserDeck = (item) => {
      configData.value.arena_data[item].chaser_decks.push({ hero: "1pQ3y4Mlbff53w9JUImx", units: ["Jc9Kugc2HGYL1lF5RRmZ", "9AuYNvoAxqsJqZ0Uhope", "0dOJkz7p6gxtSpXombTK", "UL9zqW9rTcc3e3QOL4Kr", "iTPb3ST5RMasxiXbiUHF"] })
    }

    const removeChaserDeck = (item, index) => {
      configData.value.arena_data[item].chaser_decks.splice(index, 1);
    }

    const addDeck = (item) => {
      configData.value.arena_data[item].decks.push({ hero: "1pQ3y4Mlbff53w9JUImx", units: ["Jc9Kugc2HGYL1lF5RRmZ", "9AuYNvoAxqsJqZ0Uhope", "0dOJkz7p6gxtSpXombTK", "UL9zqW9rTcc3e3QOL4Kr", "iTPb3ST5RMasxiXbiUHF"] })
    }

    const removeDeck = (item, index) => {
      configData.value.arena_data[item].decks.splice(index, 1);
    }

    const submit = () => {
      isLoading.value = true;
      store.dispatch('serverConfig/updateMiniTournamentConfig', configData.value).finally(() => {
        isLoading.value = false;
      })
    };

    return {
      submit,
      configData,
      chests: computed(() => store.getters["chests/getChests"]),
      heroes: computed(() => store.getters["heroes/getHeroes"].filter(x => x.status === 1)),
      units: computed(() => store.getters["units/getUnits"].filter(x => x.status === 1)),
      isLoading,
      updateRewards,
      selectedArena,
      selectArena,
      addChaserDeck,
      removeChaserDeck,
      addDeck,
      removeDeck
    };
  },
};
</script>

<style scoped>
.main-container {
  padding: 0 30px;
  height: 100%;
  width: 100%;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-panel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.top-panel-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  min-height: 20px;
  text-align: left;
  padding: 20px;
}

.reward-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid grey;
}

.reward-row.bold {
  font-weight: bold;
}

.input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.arena-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
}

.arena-item {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid grey;
}

.arena-item.interactable:hover {
  background-color: aliceblue;
  cursor: pointer;
}

.deck {
  padding: 2px 0px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  gap: 10px;
}

.clickable-text {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  font-size: 15px;
}

.right-align {
  width: 100%;
  text-align: center;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  padding: 5px;
  font-size: 12px;
  visibility: hidden;
  width: 250px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

</style>